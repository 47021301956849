import request from '@/utils/request'
import ucenter_request from '@/utils/ucenter_request'
import qs from 'qs'

/**
 * [获取注册信息]
 **/
export function getRegistInfo() {
  return ucenter_request({
    url: '/personal/Myinfo/getRegisInfo',
    method: 'get'
  })
}

/**
 * [获取注册信息-新接口-林杰]
 **/
export function getNewRegisInfo() {
  return ucenter_request({
    url: '/personal/Myinfo/getNewRegisInfo',
    method: 'get'
  })
}
/**
 * [获取注册信息-新接口-林杰]
 **/
export function newRegisInfo(params) {
  return ucenter_request({
    url: '/personal/Myinfo/newRegisInfo',
    method: 'post',
    contentType: 'application/x-www-from-urlencoded',
    data: qs.stringify(params)
  })
}
/**
 * [修改头像]
 **/
export function updateIcon(params) {
  return ucenter_request({
    url: '/personal/Myinfo/setAvatar',
    method: 'get',
    params: params
  })
}
/**
 * [发送手机验证码]
 **/
export function sendPhoneCode(params) {
  return ucenter_request({
    url: '/personal/Myinfo/sendMobileCode',
    method: 'get',
    params: params
  })
}
/**
 * [绑定手机号]
 **/
export function bindPhone(params) {
  return ucenter_request({
    url: '/personal/Myinfo/batchMobile',
    method: 'get',
    params: params
  })
}
/**
 * [发送邮箱验证码]
 **/
export function sendEmailCode(params) {
  return ucenter_request({
    url: '/personal/Myinfo/sendEmailCode',
    method: 'get',
    params: params
  })
}
/**
 * [绑定邮箱]
 **/
export function requestBindEmail(params) {
  return ucenter_request({
    url: '/personal/Myinfo/batchEmail',
    method: 'get',
    params: params
  })
}

/**
 * [修改注册信息]
 * realname：真实姓名
 * gender：手机号
 * birthday：2017-09-09
 * specialty_id：专业Id
 * province_id 省Id
 * city_id:市id
 * email：邮箱
 **/
export function updateRegistInfo(params) {
  return ucenter_request({
    url: '/personal/Myinfo/regisInfo',
    method: 'get',
    params: params
  })
}
/**
 * [获取专业列表]
 **/
export function getMajorList(params) {
  return ucenter_request({
    url: '/personal/Myinfo/getMajorList',
    method: 'get',
    params: params
  })
}

/**
 * [设置默认地址|删除地址]
 * id:必填
 * is_default：1：默认 0取消默认
 * status:删除传0 默认是1
 **/
export function setDefaultAndDelete(params) {
  return ucenter_request({
    url: '/user/address/updateAddress',
    method: 'get',
    params: params
  })
}

/**
 * [收货地址列表]
 **/
export function getUserAddressList() {
  return ucenter_request({
    url: 'user/address/getUserAddressList',
    method: 'get'
  })
}
/**
 * [收货地址列表]
 **/
export function getUserAddressListThree(params) {
  return ucenter_request({
    url: 'user/address/getPCCList',
    method: 'get',
    params: params
  })
}

/**
 * [获取修改地址信息]
 **/
export function getNeedEdiLocalInfo(params) {
  return ucenter_request({
    params: params,
    url: '/user/address/getOneAddressInfo',
    method: 'get'
  })
}
/**
 * [修改密码]
 **/
export function updatePwd(params) {
  return ucenter_request({
    params: params,
    url: '/personal/Myinfo/updatePassword',
    method: 'get'
  })
}
/**
 * [修改密码](新)
 **/
export function changePwd(params) {
  return ucenter_request({
    params: params,
    url: '/ucenter/user/changePwd',
    method: 'POST'
  })
}

/**
 * [修改密码 -- 发送验证码]
 **/
export function sendMobile(params) {
  return ucenter_request({
    params: params,
    url: '/user/pwd/sendMobile',
    method: 'get'
  })
}
/**
 * [修改密码 -- 验证手机验证码]
 **/
export function vertifyMobile(params) {
  return ucenter_request({
    params: params,
    url: '/user/pwd/vertifyMobile',
    method: 'get'
  })
}
/**
 * [修改密码 -- (最新)]
 **/
export function updatePwdNew(params) {
  return ucenter_request({
    params: params,
    url: '/user/pwd/updatePwd',
    method: 'get'
  })
}

/**
 * [添加/修改地址]
 * id:修改必填
 * receiver：收货人必填
 * mobile：手机号必填
 * province_id：省id 必填
 * city_id：市id 必填
 * detail_address：详细地址 必填
 **/
export function editAddress(params) {
  return ucenter_request({
    url: 'user/address/editAddress',
    method: 'get',
    params: params
  })
}

/**
 * [筑龙币记录]
 **/
export function getCoinLog(params) {
  return ucenter_request({
    url: '/personal/Myinfo/myInteg',
    method: 'get',
    params: params
  })
}

/**
 * [荣誉分记录]
 **/
export function getHonorLog(params) {
  return ucenter_request({
    url: '/personal/Myinfo/gloryScoreLog',
    method: 'get',
    params: params
  })
}

/**
 * [会员升级记录]
 **/
export function getVipUpdateLog(params) {
  return ucenter_request({
    url: '/personal/Myinfo/vipUpgradutingLog',
    method: 'get',
    params: params
  })
}

/**
 * [获取学历信息]
 **/
export function getEduConfig() {
  return ucenter_request({
    url: '/personal/Myinfo/getEduBackList',
    method: 'get'
  })
}

/**
 * [获取城市列表]
 * level 1 省 市
 * parent_id：省ID
 **/
export function getProvinceCityList(params) {
  return ucenter_request({
    url: '/personal/Myinfo/getProvinceCityList',
    method: 'get',
    params: params
  })
}

// 我的足迹---浏览帖子
export function myview(params) {
  return request({
    url: 'learnCenter/mygroup/myview',
    method: 'get',
    params: params
  })
}

// 我的足迹--评测记录
export function getMySkexamBrowse(params) {
  return request({
    url: 'learnCenter/browse/getMySkexamBrowse',
    method: 'get',
    params: params
  })
}

// 我的足迹--浏览课程
export function getMyLessonBrowse(params) {
  return request({
    url: 'learnCenter/browse/getMyLessonBrowse',
    method: 'get',
    params: params
  })
}

/**
 * [个人主页---主题--列表下数据]
 **/
export function myPost(params) {
  return request({
    url: '/personal/myinfo/myPost',
    method: 'get',
    params: params
  })
}

// [个人主页---档案--]
export function myArchive(params) {
  return request({
    url: '/personal/Myinfo/myArchive',
    method: 'get',
    params: params
  })
}

// [个人主页---筑龙币--]
export function getRow(params) {
  return request({
    url: '/setting/bbsscore/getRow',
    method: 'get',
    params: params
  })
}
// [个人主页---任务中心--]
export function newTaskIndex(params) {
  return request({
    url: '/personal/Task/index',
    method: 'get',
    params: params
  })
}
// 完成关注任务领奖接口
export function followGetScore(params) {
  return request({
    url: '/personal/Task/followGetScore',
    method: 'get',
    params: params
  })
}
// 满意度调查（获取）
export function getUserPostSatisfaction(params) {
  return request({
    url: 'personal/myinfo/getUserPostSatisfaction',
    method: 'get',
    params: params
  })
}
// 满意度调查（保存）
export function saveUserPostSatisfaction(params) {
  return request({
    url: 'personal/myinfo/saveUserPostSatisfaction',
    method: 'post',
    contentType: 'application/x-www-from-urlencoded',
    data: qs.stringify(params)
  })
}
// 我的账户---学分记录
export function getUserCredit(params) {
  return request({
    url: '/personal/Myinfo/myCredit',
    method: 'get',
    params: params
  })
}

// 专家---讲过的课程
export function getLecturerLesson(params) {
  return request({
    url: '/personal/Myinfo/getLecturerLesson',
    method: 'get',
    params: params
  })
}

// 获取专家信息
export function getRegisInfoByToken(params) {
  return request({
    url: '/personal/Myinfo/getRegisInfoByToken',
    method: 'get',
    params: params
  })
}
// 保存专家信息
export function regisInfoByToken(params) {
  return request({
    url: '/personal/Myinfo/regisInfoByToken',
    method: 'get',
    params: params
  })
}
// 关注列表
export function getFollows(params) {
  return request({
    url: '/personal/Myinfo/getFollows',
    method: 'get',
    params: params
  })
}
// 关注允许观看
export function showFollows(params) {
  return request({
    url: '/personal/Myinfo/showFollows',
    method: 'get',
    params: params
  })
}
// 粉丝列表
export function getFans(params) {
  return request({
    url: '/personal/Myinfo/getFans',
    method: 'get',
    params: params
  })
}
// 粉丝允许观看
export function showFans(params) {
  return request({
    url: '/personal/Myinfo/showFans',
    method: 'get',
    params: params
  })
}
// 邀请图
export function getRegImg(params) {
  return ucenter_request({
    url: '/personal/Task/getRegImg',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 补签接口
export function repaireSign(params) {
  return ucenter_request({
    url: '/personal/Task/repaireSign',
    method: 'post',
    data: qs.stringify(params)
  })
}